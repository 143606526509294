import UpdateNftName from "Component/Modals/UpdateNftModal";
import { claimNftsTypes, getNetworkUrl, wait } from "helpers/constants";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { getNftDetailsById, getNftMetadataByTokenId } from "helpers/backend_helper";
import SpecialPowerModal from "Component/Modals/SpecialPowerModal";
import useWallet from "hooks/wallet";
import WeaponsAndGearModal from "Component/Modals/WeaponsGearModal";
import { useNavigate, useParams } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import NftBgColorModal from "Component/Modals/NftBgColorModal";
import NftSkullColorModal from "Component/Modals/NftSkullColorModal";
import { getCollection, updateUserNftMetadataRequest } from "store/actions";
import { toast } from "react-toastify";
import NftDroneEquipModal from "Component/Modals/NftDroneEquipModal";
import Web3Intraction from "util/web3Intraction";
import CarrotechProgress from "Component/Modals/CarrotechProgress";

const Workshop = () => {
  const { token_id } = useParams();
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.Setting);
  const { loadingUpdateNft } = useSelector(state => state.Items);
  const { user } = useSelector((state) => state.Login);
  const [loading, setLoading] = useState(null);
  const [userDetail, setUserDetails] = useState();
  const [rrsCollection, setRRSCollection] = useState(null);
  const [showSpecialPowerModal, setShowSpecialPowerModal] = useState(false);
  const [showWeaponsAndGearModal, setShowWeaponsAndGearModal] = useState(false);
  const [showNftBgColorModal, setShowNftBgColorModal] = useState(false);
  const [showNftSkullColorModal, setShowNftSkullColorModal] = useState(false);
  const [showLegendaryDroneModal, setShowLegendaryDroneModal] = useState(null);
  const [nftData, setNftData] = useState(null);
  const [showUpdateNftModal, setShowUpdateNftModal] = useState(false);
  const [armorsCollection, setArmorsCollection] = useState(null);
  const [carrotectNftsCount, setCarrotecNftsCount] = useState(0);
  const [nftsList, setNftsList] = useState(0);
  const wallet = useWallet();
  const [carrotectProgress, setCarrotectProgress] = useState({
    show: false,
    type: "",
    item: ""
  });
  const navigate = useNavigate();

  function generateOpenSeaUrl(network, contractAddress, tokenId) {
    const baseUrl = network === 'mainnet' ? 'https://opensea.io' : `https://testnets.opensea.io`;
    const networkType = network === "mainnet" ? "ethereum" : "sepolia";
    console.log("networkType", networkType);
    console.log("network", network)
    return `${baseUrl}/assets/${networkType}/${contractAddress}/${tokenId}`;
  }


  function formatNFTData(nftDataArray) {
    const openSeaIncluded = nftDataArray.map((nft) => ({
      ...nft,
      opensea_url: generateOpenSeaUrl(settings?.blockchainNetworkMode, nft?.contract?.address?.toLowerCase(), nft?.tokenId)
    }))
    return openSeaIncluded;
  }


  useEffect(() => {

    if (
      (armorsCollection &&
        armorsCollection?.contractAbi) &&
      settings &&
      JSON.stringify(settings) !== "{}"
    ) {
      const web3Intraction = new Web3Intraction(
        armorsCollection?.blockchain,
        settings
      );
      setNftsList([]);
      if (userDetail?.walletAddress) {
        (async () => {

          if (!armorsCollection?.contractAddress) {
            return toast.error(`Contract address is missing!`)
          }

          const contractAddresses = [];
          armorsCollection?.contractAddress && contractAddresses.push(armorsCollection.contractAddress);

          const params = {
            owner: userDetail?.walletAddress,
            contractAddresses: contractAddresses,
            pageKey: undefined,
            blockchain: armorsCollection.blockchain
          }
          try {
            setLoading(true);
            console.log("params", params)
            const response = await web3Intraction.getNfts(params);
            const { ownedNfts,
              pageKey,
              totalCount } = response;
            console.log("ownedNfts", ownedNfts)
            // setPageKey(pageKey); 
            // setTotalNfts(totalCount);
            // let contractNfts = (ownedNfts || []).filter((item)=>item?.contract?.address?.toLowerCase() === rrsCollection.contractAddress?.toLowerCase());
            let contractNfts = (formatNFTData(ownedNfts, settings?.blockchainNetworkMode) || []);
            console.log("contractNfts", contractNfts)
            setNftsList(contractNfts);
            setLoading(false);

          } catch (e) {
            setLoading(false);

            console.log("Error while getting nft's", e);
          } finally {
            setLoading(false);
            // handleHideProgress();
          }
        })();
      } else {
        // handleHideProgress();
      }
    }
  }, [
    userDetail?.walletAddress,
    JSON.stringify(armorsCollection),
    JSON.stringify(settings),
  ]);


  useEffect(() => {
    if (settings && settings.activeCollectionId) {
      dispatch(getCollection(settings.activeCollectionId, (data) => {
        setRRSCollection(data);
      }));
    }
    if (settings && settings.activeArmorsCollectionId) {
      dispatch(getCollection(settings.activeArmorsCollectionId, (data) => {
        setArmorsCollection(data);
      }));
    }
  }, [JSON.stringify(settings)]);

  useEffect(() => {
    if (user) {
      setUserDetails({ ...user, walletAddress: user?.walletId?.walletAddress });
    }
  }, [user]);


  useEffect(async () => {
    try {
      if (
        nftData &&
        armorsCollection &&
        armorsCollection.contractAbi &&
        settings &&
        JSON.stringify(settings) !== "{}"
      ) {

        setCarrotectProgress({
          show: true,
          type: "init",
          item: null
        });

        await wait(1000);

        setCarrotectProgress({
          show: true,
          type: "loading",
          item: null
        });

        const web3Intraction = new Web3Intraction(
          armorsCollection.blockchain,
          settings
        );

        const contract = await web3Intraction.getContract(
          armorsCollection.contractAbi,
          armorsCollection.contractAddress
        );
        console.log("armory contract", contract)
        if (!contract) {
          return;
        }
        const networkUrl = getNetworkUrl("base", settings);
        console.log("networkUrl", networkUrl);
        let chainID = networkUrl?.chainId;
        if (
          wallet.account &&
          wallet.account?.toLowerCase() ===
          userDetail?.walletAddress?.toLowerCase()
        ) {

          await wallet.switchNetwork(
            chainID
          );

        }

        //38 is carrotec tokenIF
        console.log("wallet.accountwallet.account", wallet.account)
        const carrotechNfts = await contract.methods.balanceOf(wallet.account, 38).call();
        console.log("carrotechNfts", carrotechNfts, "wallet.account", wallet.account);

        if (carrotechNfts > 0 && nftData?.isEditable) {
          await wait(1000);

          setCarrotectProgress({
            show: true,
            type: "grant",
            item: nftData
          });
          await wait(2000);
          setCarrotecNftsCount(carrotechNfts);
          console.log("nftData", nftData)
        } else {
          await wait(1000);

          setCarrotectProgress({
            show: true,
            type: "denied",
            item: null
          });
        }
      }

    } catch (err) {
      console.log("error", err)
      await wait(1000);
      setCarrotectProgress({
        show: false,
        type: "",
        item: null
      });
    }
  }, [
    userDetail?.walletAddress,
    wallet?.account,
    JSON.stringify(armorsCollection),
    JSON.stringify(settings),
    nftData
  ])


  useEffect(async () => {
    try {
      if (wallet.account) return;
      console.log("cliecked");
      await wallet.initializeOnBoard();

      if (!wallet.account && settings?.blockchainNetworkMode && JSON.stringify(settings) !== "{}") {
        const networkUrl = getNetworkUrl("base", settings);
        console.log("networkUrl onConnectMetamask", networkUrl);
        wallet.connect(
          "metamask",
          networkUrl.chainId
        );

      }
    } catch (err) { }
  }, [wallet.account]);


  function generateOpenSeaUrl(network, contractAddress, tokenId) {
    const baseUrl = network === 'mainnet' ? 'https://opensea.io' : `https://testnets.opensea.io`;
    const networkType = network === "mainnet" ? "ethereum" : "sepolia";
    console.log("networkType", networkType);
    console.log("network", network)
    return `${baseUrl}/assets/${networkType}/${contractAddress}/${tokenId}`;
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log("outside")
        if (token_id) {
          console.log("inside")
          const response = await getNftMetadataByTokenId({ tokenId: token_id, nft_type: "og-mint" });
          console.log("response", response);
          if (response?.status === "success") {
            let newData = {};
            const opensea_url = generateOpenSeaUrl(settings?.blockchainNetworkMode, rrsCollection?.contractAddress?.toLowerCase(), response?.data?.edition);
            newData = {
              ...response.data,
              token_id: response.data.edition,
              opensea_url
            }
            console.log("newData", newData)
            setNftData(newData);
          }
          setLoading(false)
        }
      } catch (err) {
        setLoading(false);
      } finally {
        setLoading(false);
      }

    }
    if (settings && token_id && rrsCollection?._id) {

      fetchData();
    }

  }, [token_id, JSON.stringify(settings), rrsCollection]);


  //   useEffect(() => {
  //     if (settings && settings.activeCollectionId) {
  //       dispatch(getCollection(settings.activeCollectionId,(data)=>{
  //         setRRSCollection(data);
  //       }));
  //     }
  //   }, [JSON.stringify(settings)]);


  function isValidName(name) {
    // Regular expression to check for the presence of numbers
    var regex = /\d/;

    // Test the name against the regular expression
    if (regex.test(name)) {
      return false; // Name contains numbers
    } else {
      return true; // Name is valid
    }
  }


  const onUpdateNftName = (name) => {
    if (!isValidName(name)) {
      return toast.error("Please enter name without number and special character.token id will automatic adjust");
    }
    dispatch(updateUserNftMetadataRequest({
      name: name,
      tokenId: nftData?.token_id,
      type: "og_mint"
    }, () => {
      setShowUpdateNftModal(false);
      window.location.reload();
      //  setNftsList((prev)=> prev?.map((item)=> item?.tokenId === showUpdateNftModal?.nftData?.tokenId ? {...item,rawMetadata:{...item.rawMetadata, name : name}} : item))

    }))
    console.log("updateNft", name)
  }


  const specialPowerValue = nftData?.attributes?.find((attribute) => attribute.trait_type === "Special Power")?.value;
  const weaponAndGearValue = nftData?.attributes?.find((attribute) => attribute.trait_type === "Weapons and Gear")?.value;
  const legendaryDroneValue = nftData?.attributes?.find((attribute) => attribute.trait_type === "Drone")?.value;
  const isEquipedWeapon = (nftData?.weaponsAndGearDetails && nftData?.weaponsAndGearDetails?.equipment_status === "equiped" || !nftData?.weaponsAndGearDetails);
  const isEquipedPower = (nftData?.specialPowerDetails && nftData?.specialPowerDetails?.equipment_status === "equiped" || !nftData?.specialPowerDetails);
  const isEquipedLegendaryDrone = (nftData?.legendaryDroneDetails && nftData?.legendaryDroneDetails?.equipment_status === "equiped" || !nftData?.legendaryDroneDetails);
  const showPowerBtn = (specialPowerValue && specialPowerValue !== "None") || nftData?.specialPowerDetails;
  const showGearBtn = (weaponAndGearValue && weaponAndGearValue !== "None") || nftData?.weaponsAndGearDetails;
  const showLegendaryDrone = (legendaryDroneValue && legendaryDroneValue !== "None" || nftData?.legendaryDroneDetails);

  return (
    <Fade>

      <section className="Nfts_section common-pad">
        <UpdateNftName
          loading={loadingUpdateNft}
          onUpdate={onUpdateNftName}
          show={showUpdateNftModal}
          name={nftData?.name}
          onhide={() => {
            setShowUpdateNftModal(false)
          }} />
        {showSpecialPowerModal ?
          <SpecialPowerModal
            nftData={nftData}
            show={showSpecialPowerModal}
            userDetail={userDetail}
            onhide={() => {
              setShowSpecialPowerModal(false)
            }}
          />
          : null
        }
        {showWeaponsAndGearModal ?
          <WeaponsAndGearModal
            nftData={nftData}
            show={showWeaponsAndGearModal}
            userDetail={userDetail}
            onhide={() => {
              setShowWeaponsAndGearModal(false)
            }}
          />
          : null
        }
        {showNftBgColorModal ?
          <NftBgColorModal
            nftData={nftData}
            show={showNftBgColorModal}
            userDetail={userDetail}
            onhide={() => {
              setShowNftBgColorModal(false)
            }}
          />
          : null
        }
        {showNftSkullColorModal ?
          <NftSkullColorModal
            nftData={nftData}
            show={showNftSkullColorModal}
            userDetail={userDetail}
            onhide={() => {
              setShowNftSkullColorModal(false)
            }}
          />
          : null
        }
        {showLegendaryDroneModal ?
          <NftDroneEquipModal
            nftData={nftData}
            show={showLegendaryDroneModal}
            userDetail={userDetail}
            onhide={() => {
              setShowLegendaryDroneModal(false)
            }}
          />
          : null}
        {carrotectProgress.show ?
          <CarrotechProgress
            show={carrotectProgress.show}
            item={carrotectProgress.item}
            type={carrotectProgress.type}
            onhide={() => {
              setCarrotectProgress({ show: false, type: "", item: null })
            }}
          />
          : null
        }
        <Container>
          <div class="Workshop_content">
            <h1>Workshop</h1>
          </div>
          {nftData && nftData?.isEditable && carrotectNftsCount > 0 ?
            <div className="nft-equip-card-wrap">
              <h5 className="text-white">{nftData?.name}
                <Button className="nft_edit mx-2" onClick={() => {
                  setShowUpdateNftModal(true)
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><path fill="#fff" d="m19.3 8.925l-4.25-4.2l1.4-1.4q.575-.575 1.413-.575t1.412.575l1.4 1.4q.575.575.6 1.388t-.55 1.387L19.3 8.925ZM17.85 10.4L7.25 21H3v-4.25l10.6-10.6l4.25 4.25Z"></path></svg>
                </Button>
              </h5>

              <hr />
              <div className="workshop-nft-wrap">
                {/* <Row>
                <Col lg={4} md={4} sm={4} xs={12}> */}
                <div className="nft_cover mb-3 position-relative">

                  <div className={`animate_box`}>
                    <span class="top"></span>
                    <span class="right"></span>
                    <span class="bottom"></span>
                    <span class="left"></span>
                    <LazyLoad debounce>
                      <img
                        src={`${nftData?.image}?t=${new Date().getTime()}`}
                        alt=""
                        className="img-fluid lazy-media"
                      // onLoadedData={handleImageLoad}
                      // onError={handleImageLoad}
                      />
                    </LazyLoad>
                    <div className="btn_openseawrap mt-2 h-100">
                      <div className="d-flex justify-content-center align-items-center flex-wrap gap-10">
                        {showPowerBtn &&
                          <Button style={{ width: 200 }} className="btn_openseas  my-1" onClick={
                            () => {
                              setShowSpecialPowerModal(true)
                            }
                          }>{isEquipedPower ? "UnEquip" : "Equip"} Power</Button>
                        }
                        {showGearBtn &&
                          <Button style={{ width: 200 }} className="btn_openseas  my-1" onClick={() => {
                            setShowWeaponsAndGearModal(true);
                          }}>{isEquipedWeapon ? "UnEquip" : "Equip"} Gear</Button>
                        }
                        {showLegendaryDrone &&
                          <Button style={{ width: 200 }} className="btn_openseas  my-1" onClick={() => {
                            setShowLegendaryDroneModal(true);
                          }}>{isEquipedLegendaryDrone ? "UnEquip" : "Equip"} Drone</Button>
                        }
                        <Button onClick={() => setShowNftBgColorModal(true)} style={{ width: 200 }} className="btn_openseas  my-1" >
                          Change Background
                        </Button>
                        <Button onClick={() => setShowNftSkullColorModal(true)} style={{ width: 200 }} className="btn_openseas  my-1" >
                          Change Skull
                        </Button>
                      </div>
                    </div>
                  </div>

                </div>
                {/* </Col>
                </Row> */}

              </div>
            </div>
            : null}
        </Container>
      </section>
    </Fade>
  );
};

export default Workshop;
